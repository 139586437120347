export default function ContactForm() {
    return (
        <div id="contact-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
            <div class="relative p-4 w-full max-w-md h-full md:h-auto">
                {/* <!-- Modal content --> */}
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex justify-end p-2">
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="contact-modal">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div class="flex justify-center " >
                        <div class=" px-5 py-10 bg-white  dark:bg-gray-800 text-black">

                            <div class="mb-6 text-3xl font-light text-center text-gray-800 dark:text-white">
                                Contact us !
                            </div>
                            <form action="https://api.web3forms.com/submit" method="POST">

                                <input type="hidden" name="access_key" value="de1bc2c4-e167-4440-9fab-045bd23fd1e0" />
                                <input type="hidden" name="subject" value="New Contact us form submission" />
                                <input type="hidden" name="from_name" value="LSHC Contact Modal" />
                                <input type="checkbox" name="botcheck" class="hidden" style={{ display: "none" }} />

                                <input type="text" name="name" required placeholder="name" className="w-full mb-1 rounded-lg border-transparent appearance-none border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent"></input>
                                <input type="text" name="phone" required placeholder="phone" className="w-full mb-1  rounded-lg border-transparent appearance-none border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent"></input>
                                <input type="email" name="email" required placeholder="email" className="w-full mb-1 rounded-lg border-transparent appearance-none border border-gray-300 focus:ring-2 focus:ring-purple-600 focus:border-transparent"></input>
                                <input type="hidden" name="redirect" value="https://web3forms.com/success" />
                                <br />
                                <button type="submit" className="rounded-lg text-base text-white bg-green-500 px-2 py-2 hover:bg-green-700">Submit</button>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}