import Hero from '../components/hero';
import HomeServices from '../components/homeServices';
import Stat from '../components/stat';
import Features from '../components/features';

export default function Home() {
  return (
    <div>
      <Hero />
      <HomeServices />
      <Stat />
      <Features />
    </div>
  );
}

