import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Home from './pages/home'
import Contact from './pages/contact'
import Product from './pages/product'

import Navbar from './components/navbar'
import Footer from './components/footer'
import ContactWidget from './components/contactWidget'
import AboutUs from './pages/aboutUs';

class App extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='contact' element={<Contact />} />
            <Route path='about' element={<AboutUs />} />
            <Route path='services' element={<Product />} />

          </Routes>
        </BrowserRouter>
        <Footer />
        <ContactWidget />
      </div>
    );
  }
}

export default App;