import LOGO from '../images/logot.png'

export default function Footer() {
    return (

        <footer class="p-4 bg-white rounded-lg shadow md:px-6 md:py-8 dark:bg-gray-800">
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div class="sm:flex sm:items-center sm:justify-between">
                <a href="/" class="flex items-center mb-4 sm:mb-0">
                    <img src={LOGO} class="mr-3 h-48" alt="LOGO" />
                    {/* <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
                </a>
                <ul class="items-center mb-6 text-base text-gray-500 sm:mb-0 dark:text-gray-400">
                    <li>
                        <a href="/" class="mr-4 hover:underline md:mr-6 ">Home</a>
                    </li>
                    <li>
                        <a href="/about" class="mr-4 hover:underline md:mr-6">About</a>
                    </li>
                    <li>
                        <a href="/services" class="mr-4 hover:underline md:mr-6 ">Services</a>
                    </li>
                    <li>
                        <a href="/contact" class="hover:underline">Contact</a>
                    </li>
                </ul>
                <ul className='grid grid-cols-1 gap-4'>
                    <form action="https://api.web3forms.com/submit" method="POST">

                        <input type="hidden" name="access_key" value="de1bc2c4-e167-4440-9fab-045bd23fd1e0" />
                        <input type="hidden" name="subject" value="Footer contact us form submission" />
                        <input type="hidden" name="from_name" value="LSHC footer form" />
                        <input type="checkbox" name="botcheck" class="hidden" style={{ display: "none" }} />

                        <li> <input type="text" name="name" required placeholder="name" className="w-full h-8 mb-1 rounded-lg border-transparent appearance-none border border-gray-300 focus:ring-2 focus:ring-green-600 focus:border-transparent"></input></li>
                        <li>  <input type="text" name="phone" required placeholder="phone" className="w-full h-8 mb-1  rounded-lg border-transparent appearance-none border border-gray-300 focus:ring-2 focus:ring-green-600 focus:border-transparent"></input></li>
                        <li>  <input type="email" name="email" required placeholder="email" className="w-full h-8 mb-1 rounded-lg border-transparent appearance-none border border-gray-300 focus:ring-2 focus:ring-green-600 focus:border-transparent"></input></li>
                        <input type="hidden" name="redirect" value="https://web3forms.com/success" />
                      
                        <li>  <button type="submit" className="rounded-lg text-base text-white bg-green-500 p-2 text-center hover:bg-green-700">Submit</button></li>


                    </form>

                </ul>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://lifesaviourhealthcare.com" class="hover:underline">LSHC™</a>. All Rights Reserved.
            </span>
        </footer>

    )
}