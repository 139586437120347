import { serviceData } from "../data/serviceData";

import ServiceHero from "../components/services/serviceHero";
import ServiceIcon from "../components/services/serviceIcon";
import ServiceCta from "../components/services/serviceCta";


export default function Product() {
    return (
        <div>
            <ServiceHero />

            <div className="grid sm:grid-cols-3 gap-4 justify-evenly p-12 grid-cols-2 open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 ">
                {serviceData.map((sData) => (
                    <div className="m-px shadow-2xl sm:h-4/5 h-3/5 overflow-y-auto p-6" key={sData.name}>
                        <div className="sm:text-xl text-green-500 select-none text-xs font-semibold text-center pb-4 break-normal">{sData.name}</div>
                        <span className="sm:text-sm text-black dark:text-white text-xs text-justify break-normal ">{sData.description}</span>
                    </div>
                ))}
            </div>
            <ServiceCta />
            <ServiceIcon />
        </div >
    )

}