import React from "react";

export default function Stat() {
    return (

        <section className=" dark:bg-gray-800 bg-green-200 px-4 py-12 mx-auto sm:py-16 sm:px-6 lg:px-8 lg:py-20">
            <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold leading-9 text-green-800 sm:text-4xl sm:leading-10">
                    We are Bengaluru's leading home care service providers from 2018.
                </h2>
                <p className="mt-3 text-base leading-7 sm:mt-4 text-green-800">
                    Our Doctors, nurses & physios are one of the best in industry.
                </p>
            </div>
            <div className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
                <div>
                    <p className="text-5xl font-extrabold leading-none text-green-800">
                        550+
                    </p>
                    <p className="mt-2 text-base font-medium leading-6 text-green-800">
                        Patients
                    </p>
                </div>
                <div className="mt-10 sm:mt-0">
                    <p className="text-5xl font-extrabold leading-none text-green-800">
                        20+
                    </p>
                    <p className="mt-2 text-base font-medium leading-6 text-green-800">
                        Doctors
                    </p>
                </div>
                <div className="mt-10 sm:mt-0">
                    <p className="text-5xl font-extrabold leading-none text-green-800">
                        70+
                    </p>
                    <p className="mt-2 text-base font-medium leading-6 text-green-800">
                        Nurses
                    </p>
                </div>
            </div>
        </section>

    )
}