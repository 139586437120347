import ContactForm from '../components/contactForm'

export default function ContactWidget() {
    return (
        <div className="contact_float hover:bg-green-400">
            <i class=" fa fa-solid fa-comments contact-icon  focus:ring-green-500 focus:ring-offset-green-200" id="dropdownTopButton" data-dropdown-toggle="dropdownTop" data-dropdown-placement="top"></i>
            <div id="dropdownTop" class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                <ul aria-labelledby="dropdownTopButton">

                    <li>
                        {/* WhatsApp icon */}
                        <a
                            href="https://wa.me/+919399887828"
                            class="whatsapp_float"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i class="fa fa-whatsapp whatsapp-icon"></i>
                        </a>
                    </li>
                    <li className="call_float">
                        <a href="tel:+919399887828"><i className="fa fa-solid fa-phone call-icon" /></a>
                    </li>

                    <li className="message_float" >
                        <button type="button" data-modal-toggle="contact-modal"><i className="fa fa-solid fa-envelope message-icon" /></button>

                    </li>
                </ul>
            </div>
            {/* invoking contact modal */}
            <ContactForm />
        </div>
    )
}