import React from "react";

const services = [
    {
        name: 'Doctor at home',
        description:
            "LSHC's team of expert, specialized doctors",
        imageLink: 'https://i.pinimg.com/736x/b5/75/7a/b5757a2eed2300820433e4083ae635c2.jpg'
    },
    {
        name: 'Nurse at home',
        description:
            'Compassionate and skilled nursing staff.',
        imageLink: 'https://thumbs.dreamstime.com/b/vector-illustration-doctor-taking-care-patient-lying-hospital-bed-woman-doctor-taking-care-patient-lying-204503154.jpg'

    },
    {
        name: 'Physiotherapy',
        description:
            'Professionally trained physiotherapists',
        imageLink: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQntqZaiDi5qAIjyYRoJkCbRKXjLcIO7lcO0A&usqp=CAU'
    },
    {
        name: 'Investigations',
        description:
            'Sample collection and Vaccination Administration',
        imageLink: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR83GAA2gj954QoTzwaa9qVHp9BZex8QsmrA&usqp=CAU'
    },
    {
        name: 'Medical equipments',
        description:
            'Rent & Purchase equipment',
        imageLink: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgQUpwq15Z-erPhkSI8dY4CTfTqYyJwQI9fA&usqp=CAU'
    },
    {
        name: 'Elder care',
        description:
            'Long term partnership plans for geriatric care.',
        imageLink: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_R0Y55uTm2xa9-0p8D-J3mF52NQ332cGmSQ&usqp=CAU'
    },
]

export default function HomeServices() {
    return (
        <div className="container  mx-auto px-6 pt-20 pb-20 bg-white dark:bg-gray-800">
            <div className="mb-16 text-center pt-8">
                <h2 className="text-base text-green-500 font-semibold tracking-wide uppercase">
                    Services
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                    We offer wide range of services.
                </p>
            </div>
            <div className="sm:flex flex sm:flex-wrap flex-wrap justify-center items-center text-center gap-8">
                {services.map((servicesVar) => (
                    <div key={servicesVar.name} className="shadow-lg rounded-2xl p-4 relative overflow-hidden w-28 h-24 sm:w-56 sm:h-48 sm:m-4 m-2 bg-base-100 z-10 bg-opacity-100 text-center">
                        <figure><img src={servicesVar.imageLink} alt="Service Images" /></figure>
                        <div className="card-body ">
                            <h2 className="px-2 py-1 text-white bg-gray-700 text-xs rounded absolute sm:left-1/2 sm:top-1/2 left-1/4 top-2/4 bg-opacity-50">{servicesVar.name}</h2>
                            {/* <p>{servicesVar.description}</p> */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="mb-16 text-center pt-8">
                <button className="btn btn-primary"><a
                    href="/contact"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-green-800 bg-green-100 hover:bg-green-200 md:py-4 md:text-lg md:px-10"
                >
                    Request a call back!
                </a></button>
            </div>
        </div>
    )
}