import React from "react";
import { faHandHoldingDollar, faHeartCirclePlus, faHospital, faHouseChimneyMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const feat = [
    {
      name: 'Decrease in Cost',
      description:
        'With at home nursing care you can save money on commuting and hospitalization charges making home nurse in Bangalore cost very cost effective and affordable.',
      icon: faHandHoldingDollar,
    },
    {
      name: 'Improves health condition',
      description:
        'Many studies and research has proven that patients heal better and show fast recovery at home than at a hospital.',
      icon: faHeartCirclePlus,
    },
    {
      name: 'Reduces hospital stays',
      description:
        'With the availability of quality nursing services at home you can now easily discharge from a hospital and continue your recovery and treatment process at home amidst family members.',
      icon: faHospital,
    },
    {
      name: 'Attention to patients',
      description:
        'Being at home ensures you get undivided attention of the care provider. This helps in better understanding of patient and family expectations which helps care providers anticipate any impending problems, much faster than in a hospital setting',
      icon: faHouseChimneyMedical,
    },
  ]

export default function Features() {
    return (
        <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center ">
                    <h2 className="sm:text-base text-xs text-green-500 font-semibold tracking-wide uppercase">Why us & benefits of home nursing</h2>
                    <p className="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Why Choose Life Saviour Health Care Services?
                    </p>
                    <p className="mt-4 max-w-2xl text-sm sm:text-xl text-gray-500 lg:mx-auto">
                        LSHC is the single most reliable and superior quality home nursing service provider in the city having a robust team of qualified doctors,
                        nurses, physiotherapists and home healthcare aides to help you get the best of medical care at affordable rates that too at the comfort of your home.
                    </p>
                </div>

                <div className="mt-10">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                        {feat.map((featureVar) => (
                            <div key={featureVar.name} className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center sm:h-12 sm:w-12 h-8 w-8  rounded-md bg-green-500 text-white">
                                        {/* <feature.icon className="" aria-hidden="true" /> */}
                                        <FontAwesomeIcon icon={featureVar.icon} />
                                    </div>
                                    <p className="ml-16 text-base sm:text-lg leading-6 font-medium text-gray-900">{featureVar.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-sm sm:text-xl text-gray-500">{featureVar.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}