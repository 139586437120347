export default function ServiceCta() {
    return (

        <div class="bg-white dark:bg-gray-800 ">
            <div class="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                    <span class="block">
                        Need Help With Patient Care
                    </span>
                    <span class="block text-green-500">
                        at Home in Bengaluru?
                    </span>
                </h2>
                <div class="lg:mt-0 lg:flex-shrink-0">
                    <div class=" inline-flex rounded-md shadow">
                        <button type="button" class="py-4 px-6  bg-green-200 hover:bg-green-300 focus:ring-green-500 focus:ring-offset-green-200 text-green-800 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            <a href="tel:+919399887828">CALL US</a>
                        </button>
                    </div>
                    <div class="ml-3 inline-flex rounded-md shadow">
                        <button type="button" class="py-4 px-6  bg-green-600 hover:bg-green-900 focus:ring-green-500 focus:ring-offset-green-200 text-green-200 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            <a href="https://wa.me/+919399887828">
                                WHATSAPP US
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}