import React from "react";
import { serviceIconData } from "../../data/serviceIconData";


export default function ServiceIcon() {
    return (
        <div className="bg-green-200">
            <div className="text-center pt-16 pb-8 sm:text-4xl text-2xl font-extrabold text-green-800">Our Other Services Also Include</div>
            <div className="grid sm:grid-cols-4 grid-cols-2 lg:grid-cols-4 p-12 justify-evenly gap-4">
                {serviceIconData.map((sData) => (
                    <div key={sData.name}>
                        <div className=' text-center'>
                            <img src={sData.imageDate} alt="service img" className="serviceCircle" />
                            <span>
                                {sData.name}
                            </span>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}