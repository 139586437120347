
import bg from '../../images/hero2.jpg'


export default function ServiceHero() {
    return (
        <div class=" pt-12 pb-12 bg-white dark:bg-gray-800">
            <div class="w-full bg-center bg-cover h-96" style={{ backgroundImage: "url(" + bg + ")" }}>
                <div class="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
                    <div class="text-center">
                        <h2 class="text-3xl font-extrabold text-green-500 dark:text-white sm:text-6xl">Our Expert Health Care Services</h2>
                        <span className="sm:text-xl text-white text-xm">Our doctors, nurses and expert physiotherapists are available on-call for timely intervention and for your general medical needs.
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )
}