export const serviceIconData = [
    {
        name: '24/7 Available across Bengaluru',
        imageDate: require('../images/services/ambulance.gif'),
    },
    {
        name: 'Home Nurse on Call',
        imageDate: require('../images/services/homenurse.png'),
    },
    {
        name: 'Home Doctor on Call',
        imageDate: require('../images/services/homedoc.png'),
    },
    {
        name: 'Elderly Care Attendants',
        imageDate: require('../images/services/elderly.png'),
    },
    {
        name: 'Injection at Home',
        imageDate: require('../images/services/injection.gif'),
    },
    {
        name: 'IV Drip at Home',
        imageDate: require('../images/services/iv.png'),
    },
    {
        name: 'Post Surgery Dressing',
        imageDate: require('../images/services/postsurgery.png'),
    },
    {
        name: 'Administering Medication',
        imageDate: require('../images/services/adminmed.png'),
    },
    {
        name: 'Medical Equipment on Rent',
        imageDate: require('../images/services/mede.gif'),
    },
    {
        name: 'Medical Tests at Home',
        imageDate: require('../images/services/medtest.png'),
    },
    {
        name: 'ICU at Home',
        imageDate: require('../images/services/icu.png'),
    },
    {
        name: 'Dialysis at Home',
        imageDate: require('../images/services/diabetes.png'),
    },

];
